<template>
  <div class="communityAMap">
    <div id="container" :style="{ height: oHight }"></div>
    <div class="rightBox" v-show="tenantId === 'P1C2A2S1D1'">
      <div class="linju" @click="toLinju">
        <img src="./img/map_icon09.png" alt="" />
        <div class="text">邻里中心</div>
      </div>
      <div class="party" @click="toParty">
        <img src="./img/map_icon06.png" alt="" />
        <div class="text">党群服务中心</div>
      </div>
    </div>
    <div class="box" v-if="isBox">
      <div class="box-c">
        <div class="box-l" :class="{ none: !currentData.picturesUrlOne }">
          <img :src="currentData.picturesUrlOne" alt="" />
        </div>
        <div class="box-r">
          <div class="name">{{ currentData.name }}</div>
          <div class="address">{{ currentData.address }}</div>
          <div class="toGo" @click="toGo">
            <img src="./img/go.png" alt="" /> <span>到这去</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pointicon from "./img/pointicon1.png";
import { mapData } from "./mapData";
import { mapState } from "vuex";
import { publicFacilityUrl, publicFacilityDetailUrl } from "./api.js";
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "14e8f6657d33d80943c81fd846b0735b",
};

export default {
  name: "communityAMap",
  data() {
    return {
      map: "",
      oHight: "",
      longitude: "121.879969",
      latitude: "29.471382",
      currentLon: "",
      currentLat: "",
      geocoder: "",
      // areaPoint: mapData,
      areaPoint: null,
      currentData: {},
      isBox: false,
      walking: "",
      driving: "",
      geolocation: "",
      linLongitude: "121.876139",
      linLatitude: "29.473522",
    };
  },
  computed: {
    ...mapState(["tenantId"]),
  },
  created() {},
  mounted() {
    let oC = window.screen.availWidth / 375;
    this.oHight = window.screen.availHeight * oC + "px";
    let params = {
      tenantId: this.tenantId,
      curPage: 1,
      pageSize: 100,
    };
    this.$axios
      .get(`${publicFacilityUrl}`, { params: params })
      .then((res) => {
        if (res && res.code === 200) {
          res.data = res.data || {};
          res.data.records = Array.isArray(res.data.records)
            ? res.data.records
            : [];
          res.data.records.forEach((item) => {
            item.name = item.pubFacilityName;
          });
          this.areaPoint = res.data.records;
          this.initAMap();
        }
      })
      .catch(() => {});
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    toParty() {
      this.map.setCenter([this.longitude, this.latitude]);
    },
    toLinju() {
      this.map.setCenter([this.linLatitude, this.linLatitude]);
    },
    toGo() {
      let _this = this;
      this.geolocation.getCurrentPosition((status, result) => {
        if (status == "complete") {
          this.currentLon = result.position.getLng();
          this.currentLat = result.position.getLat();
          var startLngLat = [this.currentLon, this.currentLat]; //起始点坐标
          var endLngLat = [
            this.currentData.longitude,
            this.currentData.latitude,
          ]; //终点坐标
          this.walking
            ? this.walking.search(
                startLngLat,
                endLngLat,
                function (status, result) {
                  //status：complete 表示查询成功，no_data 为查询无结果，error 代表查询错误
                  //查询成功时，result 即为对应的驾车导航信息
                  if (status == "error" && result == "OVER_DIRECTION_RANGE") {
                    _this.driving.search(
                      startLngLat,
                      endLngLat,
                      (ostatus, oresult) => {
                        console.log(ostatus, oresult, "00");
                      }
                    );
                  }
                }
              )
            : "";
        }
      });
    },

    handleClick(e, value) {
      // let id = e.target.getExtData() ? e.target.getExtData().mapId : "";
      // this.areaPoint.forEach((ele, index) => {
      //   if (ele.id == id) {
      //     this.currentData = ele;
      //   }
      // });
      this.currentData = { ...value };
      this.geocoder.getAddress(
        [value.longitude, value.latitude],
        (status, result) => {
          if (status === "complete" && result.info === "OK") {
            value.address = result.regeocode.formattedAddress;
            this.currentData.address = result.regeocode.formattedAddress;
          }
        }
      );
      this.isBox = true;
      this.$axios
        .get(publicFacilityDetailUrl, {
          params: {
            tenantId: this.tenantId,
            id: value.id,
          },
        })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.picturesUrl = Array.isArray(res.data.picturesUrl)
              ? res.data.picturesUrl
              : "";
            this.$set(this.currentData, "picturesUrl", res.data.picturesUrl);
            this.$set(
              this.currentData,
              "picturesUrlOne",
              res.data.picturesUrl[0]
            );
            console.log(this.currentData, "23333");
          }
        });
    },
    onComplete(data) {
      console.log(777);
    },
    async initAMap() {
      let _this = this;
      AMapLoader.load({
        key: "6ccc57a1965684bcf1d56bf846608b44", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Scale",
          "AMap.ToolBar",
          "AMap.ControlBar",
          "AMap.Geocoder",
          "AMap.Marker",
          "AMap.CitySearch",
          "AMap.Geolocation",
          "AMap.AutoComplete",
          "AMap.InfoWindow",
          "AMap.Walking",
          "AMap.Driving",
          "AMap.MarkerCluster",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then(async (AMap) => {
          // 获取到作为地图容器的DOM元素，创建地图实例
          this.map = new AMap.Map("container", {
            //设置地图容器id
            resizeEnable: true,
            viewMode: "2D", // 使用3D视图
            showLabel: true, //不显示地图文字标记
            zoomEnable: true, // 地图是否可缩放，默认值为true
            dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
            doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true
            zoom: 20, //初始化地图级别
            center: [_this.longitude, _this.latitude], // 初始化中心点坐标 广州
            // mapStyle: "amap://styles/darkblue", // 设置颜色底层
          });
          this.map.clearMap();
          this.geocoder = new AMap.Geocoder({
            city: "全国",
          });
          let res = await Promise.all(
            _this.areaPoint.map(async (ele) => {
              let gps = [ele.longitude, ele.latitude];
              return await convertFrom(gps);
            })
          );
          this.areaPoint.forEach((ele, index) => {
            this.areaPoint[index].longitude = res[index][0];
            this.areaPoint[index].latitude = res[index][1];
          });
          function convertFrom(point, type = "gps") {
            return new Promise((resolve, reject) => {
              new AMap.convertFrom(point, type, (status, result) => {
                if (result.info === "ok") {
                  resolve([result.locations[0].lng, result.locations[0].lat]);
                }
              });
            });
          }
          const points = this.areaPoint.map((item) => ({
            ...item,
            lnglat: [item.longitude, item.latitude],
          }));
          console.log(points, "111111111");
          // 添加点聚合
          const cluster = new AMap.MarkerCluster(this.map, points, {
            gridSize: 60, // 设置网格像素大小
            renderMarker: (context) => {
              const valueData = context.data[0];
              var content = `<div style="white-space:nowrap;position:relative;font-size:14px;transform: translate(-50%, -50%)">${valueData.name}<img style="position:absolute;left:50%;top:0%;width:22px;height:30px;transform: translate(-50%, -30px)" src="${pointicon}"></div>`;
              var offset = new AMap.Pixel(0, 0);
              context.marker.setContent(content);
              context.marker.setOffset(offset);
              context.marker.on("click", (e) => {
                this.handleClick(e, valueData);
              });
            },
          });

          // const pointIcon = new AMap.Icon({
          //   // size: new AMap.Size(22, 30), //图标尺寸
          //   image: pointicon, //Icon 的图像
          //   imageOffset: new AMap.Pixel(0, 0), //图像相对展示区域的偏移量，适于雪碧图等
          //   imageSize: new AMap.Size(22, 30), //根据所设置的大小拉伸或压缩图片
          // });
          // 循环添加定位点
          // this.areaPoint.forEach((ele, index) => {
          //   this.geocoder.getAddress([ele.longitude, ele.latitude], (status, result) => {
          //     if (status === 'complete' && result.info === 'OK') {
          //       ele.address = result.regeocode.formattedAddress;
          //     }
          //   });
          //   var marker = new AMap.Marker({
          //     position: new AMap.LngLat(ele.longitude, ele.latitude), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          //     title: ele.name,
          //     icon: pointIcon,
          //     offset: new AMap.Pixel(-0, -26),
          //     extData: {
          //       mapId: ele.id,
          //     },
          //   });
          //   // 将创建的点标记添加到已有的地图实例：
          //   marker.on("click", (e) => {
          //     this.handleClick(e, ele);
          //   });
          //   this.map.add(marker);
          //   // 创建Text，指定Text的坐标和内容
          //   var text = new AMap.Text({
          //     position: new AMap.LngLat(ele.longitude, ele.latitude), //Text的坐标
          //     text: ele.name, //Text的内容
          //     offset: new AMap.Pixel(-40, 0), //Text相对于坐标的偏移量
          //   });
          //   // 将Text添加到地图中
          //   this.map.add(text);
          // });

          // 创建步行导航实例
          _this.walking = new AMap.Walking({
            map: _this.map, // 指定使用的地图
          });
          _this.driving = new AMap.Driving({
            map: _this.map, // 指定使用的地图
          });
          this.geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, // 是否使用高精度定位，默认:true
            timeout: 10000, // 超过10秒后停止定位，默认：5s
          });
          this.map.setCenter([
            this.areaPoint[0].longitude,
            this.areaPoint[0].latitude,
          ]);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped lang="less">
.communityAMap {
  width: 100%;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  /deep/ .amap-overlay-text-container {
    background: none;
    border: none;
    font-size: 22px;
    font-weight: 700;
  }
  .rightBox {
    position: fixed;
    top: 40px;
    z-index: 1222;
    right: 10px;
    .linju {
      text-align: center;
      img {
        width: 50px;
      }
      .text {
        white-space: nowrap;
        font-size: 16px;
      }
    }
    .party {
      text-align: center;
      margin-top: 40px;
      img {
        width: 50px;
      }
      .text {
        white-space: nowrap;
        font-size: 16px;
      }
    }
  }
  .box {
    position: fixed;
    transition: all 0.2s;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1222;
    width: 90%;
    height: 300px;
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    .box-c {
      display: flex;
      align-items: center;
    }
    .box-l {
      width: 240px;
      height: 240px;
      border-radius: 20px;
      flex-shrink: 0;
      margin-right: 10px;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
      &.none {
        width: 0px;
        img {
          display: none;
        }
      }
    }
    .box-r {
      .name {
        font-weight: 700;
        font-size: 30px;
      }
      .address {
        font-size: 30px;
        margin: 10px 0;
      }
      .toGo {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #3781ff;
        width: 180px;
        height: 55px;
        border-radius: 30px;
        img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
        span {
          font-size: 30px;
          color: #3781ff;
        }
      }
    }
  }
}
</style>
